<script>
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'

/* COMPOSANTS */
import AkFormList from "@components/layout/AkFormList";
import AkInputText from "@components/input/AkInputText";
import AkDialog from "@components/general/AkDialog";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import utilsMixin from "@mixins/utilsMixin";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import agerService from "@services/agerService";
import uerService from "@services/uerService";
import ceiService from "@services/ceiService";
import userGroupService from "@services/userGroupService";
import rolePermissionService from "@services/rolePermissionService";

export default {
  components: {AkFormList, AkInputText, AkDialog}, 
  mixins: [randomRef, utilsMixin, roleMixin],
  metaInfo() {
    return {
      title: "user_group.list",
    }
  },
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      list: [],
      current: {},
      agerList: [],
      uerList: [],
      ceiList: [],
      loading: false,
    }
  },
  validations() {
    return {
      current: {
        name: {required},
      }
    }
  },
  mounted() {
    this.getRef().showTotalLoader();
  
    let p0 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.admin.view);

    let p1 = agerService.findAllCurrentForAdmin();
    p1.then(data => this.agerList = data);
  
    let p2 = uerService.findAllCurrent();
    p2.then(data => this.uerList = data);

    let p3 = ceiService.findAllCurrent();
    p3.then(data => this.ceiList = data);

    let p4 = userGroupService.findAll();
    p4.then(data => this.list = data);

    Promise.all([p0, p1, p2, p3, p4]).then(()=>{
      this.loading = false;
      this.getRef().hideLoader();
    });
  },
  methods: {
    create() {
      this.getRef().resetMessages();
      this.v$.$touch();
      if (this.v$.$error) return;
      
      this.getRef().showTotalLoader();
      userGroupService.create(this.current).then(data => {
        this.list.push(data);
        this.current = {};
        
        this.getRef().success(this.$t("user_group.added"));
        this.$refs.dialogCreate.hide();
      }).finally(() => {
        this.getRef().hideLoader();
      });
    },
    update() {
      this.getRef().resetMessages();
      this.v$.$touch();
      if (this.v$.$error) return;
      
      this.getRef().showTotalLoader();
      userGroupService.update(this.current).then(data => {
        this.replaceInListById(this.list, data);
        this.current = {};
        
        this.getRef().success(this.$t("user_group.updated"));
        this.$refs.dialogUpdate.hide();
      }).finally(() => {
        this.getRef().hideLoader();
      });
    },
    delete() {
      this.getRef().resetMessages();
      userGroupService.delete(this.current).then(data => {
        this.removeInListById(this.list, data.id);
        this.getRef().success(this.$t("user_group.deleted"))
      }).catch(e => {
          this.getRef().error(e.response.data.error);
      });
    },

    openCreateDialog() {
      this.current = {};
      this.$refs.dialogCreate.show();
    },
    openEditDialog(data) {
      this.current = {...data};
      this.$refs.dialogUpdate.show();
    },
    openDeleteDialog(data) {
      this.current = data;
      this.$refs.dialogDelete.show();
    },
  },
  computed: {
    uerListForCurrent() {
      return this.uerList.filter(uer => uer.agerId === this.current.agerId);
    },
    ceiListForCurrent() {
      return this.ceiList.filter(cei => cei.uerId === this.current.uerId);
    },
  },
  watch: {
    uerListForCurrent() {
      if(!this.uerListForCurrent.some(uer => uer.id===this.current.uerId))
        this.current.uerId = undefined;
    },
    ceiListForCurrent() {
      if(!this.ceiListForCurrent.some(cei => cei.id===this.current.ceiId))
        this.current.ceiId = undefined;
    },
  },
}
</script>

<template v-if="!roleLoading && this.canViewAdmin()">
  <AkFormList :ref="ref" :title="$t('user_group.list')">
<!--    <template v-slot:action>-->
<!--      <button v-if=this.canEditAdmin() class="btn btn-inverse-primary float-right" @click="openCreateDialog()">-->
<!--        <i class="fe fe-plus pr-1"/>-->
<!--        {{ $t('add') }}-->
<!--      </button>-->
<!--    </template>-->
    <template v-slot:list>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable 
                  :loading="loading" 
                  :paginator="false"
                  :value="list"
                  class="table"
                  removableSort
                  responsiveLayout="scroll"
                  stripedRows>
                  <template #empty>
                    {{ $t("list_empty") }}
                  </template>
                  <Column field="name" :header="$t('user_group.name')" :sortable="true">
                    <template #body="slotProps">
                          {{ slotProps.data.name }}
                    </template>
                  </Column>
                  <Column bodyStyle="text-align: right; overflow: visible"
                          headerStyle=" width: 160px; text-align: center">
                    <template #body="slotProps">
                      <span v-if=this.canEditAdmin() 
                        class="btn btn-xs btn-inverse-primary pointer"
                        @click="openEditDialog(slotProps.data)">
                        <i class="fe fe-edit"/></span>
                      <span v-if="this.canDeleteAdmin() && !slotProps.data.techName"
                        class="btn btn-xs btn-inverse-danger ml-1"
                        @click="openDeleteDialog(slotProps.data)">
                        <i class="fe fe-trash"/></span>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    
    <template v-slot:extra>
      <AkDialog ref="dialogCreate"
                :title="$t('user_group.create_dialog')"
                :validate-label="$t('add')"
                width="450px"
                @validate="this.create()">
        <div class="form-row">
          <AkInputText :label="$t('user_group.name')"
              v-model="current.name"
              :validator="v$.current.name" 
              class-name="col-12"/>
        </div>
      </AkDialog>
      
      <AkDialog ref="dialogUpdate"
                :title="$t('user_group.edit_dialog')"
                :validate-label="$t('update')"
                width="450px"
                @validate="this.update()">
        <div class="form-row">
          <AkInputText :label="$t('user_group.name')"
              v-model="current.name"
              :validator="v$.current.name"
              class-name="col-12"/>
        </div>
      </AkDialog>
      
      <AkDialog ref="dialogDelete"
                :auto-hide-on-validate="true"
                :cancel-label="$t('no')"
                :title="$t('user_group.delete_dialog')"
                :validate-label="$t('yes')"
                width="450px"
                @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span >{{ $t('user_group.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </AkFormList>
</template>